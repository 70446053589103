import React, { useState, useEffect } from 'react';
import sanityClient from '../../client';

import PostItem from '../widgets/PostItem';
import './Posts.css'

const Posts = () => {
    const [postData, setPostData] = useState(null)

    useEffect(() => {
        sanityClient.fetch(`
            *[_type == "post"]{
                title,
                slug,
                mainImage{
                    asset->{
                        _id,
                        url
                    },
                    alt
                }
            }
        `).then(data => {
            setPostData(data)
        })
          .catch(console.error);
    }, [])

    return (
        <div className="post-page my-5" >
            <div className="contain">
                <div className="container-fluid">
                    <h1 className="title-slim mb-5">Posts</h1>
                    <div className="post-grid">
                        { postData && postData.map(data => <PostItem key={data.slug.current} slug={data.slug.current} img={data.mainImage.asset.url} title={data.title} />) }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Posts;
