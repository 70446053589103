import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import sanityClient from '../../client';
import imageUrlBuilder from '@sanity/image-url';
import BlockContent from '@sanity/block-content-to-react';

import './Post.css'

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
    return builder.image(source);
}

const Post = () => {
    const [postData, setPostData] = useState(null)
    const { slug } = useParams();

    useEffect(() => {
        sanityClient.fetch(`
            *[slug.current == "${ slug }"]{
                title,
                _id,
                slug,
                publishDate,
                mainImage{
                    asset->{
                        _id,
                        url
                    }
                },
                body,
                "name": author->name,
                "authorImage": author->image
            }
        `).then(data => setPostData(data[0]))
          .catch(console.error);
    }, [])

    return !postData ? (
        <div className="post-page my-5" >
            <div className="contain">
                <div className="container-fluid">
                    <h1 className="title-slim mb-5">Loading</h1>
                </div>
            </div>
        </div>
        ) : (
        <div className="post-page my-5" >
            <div className="container">
                <h1 className="title-slim mb-2">{ postData.title }</h1>
                <div className="post-title-sub ml-2">
                    <div className="author-image-container">
                        <img className="author-image" src={ urlFor(postData.authorImage).url() } alt=""/>
                    </div>
                    <div className="post-head-sub ml-3" >
                        <p className="mb-1" >By: { postData.name } </p>
                        <p > { postData.publishDate.slice(0,10) } </p>
                    </div>
                </div>
                <div className="post-image-section text-center">
                    <img className='post-image' src={ postData.mainImage.asset.url } alt=""/>
                </div>
                <div className="post-body-area mt-5">
                    <BlockContent blocks={postData.body} projectId="tlfh28z1" dataset="production" />
                </div>
            </div>
        </div>
    )
}

export default Post;
