import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'

import Navbar from './components/Navbar/Navbar';
import Footer from './components/widgets/Footer';
import Posts from './components/pages/Posts';
import Post from './components/pages/Post';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  return (
    <Router>
      <Navbar />
      <Switch>
        <Route exact path='/' component={ Posts } />
        <Route path='/:slug' component={ Post } />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
