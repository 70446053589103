import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo1.png';

import './navbar.css';

const Navbar = () => {
    const [clicked, setClicked] = useState(false);
    const [] = useState();

    const handleClick = () => {
        setClicked(!clicked);

    }

    return (
        <Fragment>
            <nav className="navbar-bg">
                <div className="contain">
                    <div className="container-fluid navbar-items">
                        <div id="logo">
                            <Link className="navbar-logo-link" to="/">
                                <span style={{fontSize: '1.4em'}} className='text-white medium'>
                                    <img style={{width: '12%', paddingRight: '1rem'}} src={logo} alt="logo"/>
                                    <span className="medium">Learning Curve</span>
                                </span>
                            </Link>
                        </div>
                        <div className="menu-icon" onClick={handleClick}>
                            <i aria-hidden className={clicked ? "fas fa-times text-white" : "fas fa-bars text-white"}></i>
                        </div>
                        <ul className={clicked ? 'nav-menu active' : 'nav-menu'}>
                            <li><a className='navbar-links' href='https://learningcurvebrampton.com/' onClick={handleClick} >Home</a></li>
                            <li><a className='navbar-links' href='https://learningcurvebrampton.com/services' onClick={handleClick} >Services</a></li>
                            <li><a className='navbar-links' href='https://learningcurvebrampton.com/about' onClick={handleClick} >About</a></li>
                            <li><a className='navbar-links' href='https://learningcurvebrampton.com/contact' onClick={handleClick} >Contact</a></li>
                            <li><Link className='navbar-links-mobile bg-yellow' to='/' onClick={handleClick} >Blog</Link></li>
                        </ul>
                    </div>
                </div>
            </nav>
        </Fragment>
    )
}


export default Navbar;
