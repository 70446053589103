import React from 'react'

const Footer = () => {
    return (
        <footer style={{padding: '1.5rem 0'}} className="bg-clean mt-4">
            <div className="align-center">
                <p style={{marginBottom: '0'}} className="text-dark" >&copy; Copyright 2020, Learning Curve</p>
            </div>
        </footer>
    )
}

export default Footer
