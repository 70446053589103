import React from 'react';
import { Link } from 'react-router-dom';

const PostItem = ({ img, title, slug }) => {
    return (
        <article style={{ backgroundImage: `url(${img})` }} className="card shadow post-item" >
            <Link className="post-item-link p-3" to={`/${slug}`} >
                <span>
                    <div className='post-item-title' >
                        <h3 className='text-white' >{ title }</h3>
                    </div>
                </span>
            </Link>
        </article>
    )
}

export default PostItem;
